import React from 'react'
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import mediaqueries from "@styles/media";

// Implementing Dave Liepmann's Tufte class
// (https://edwardtufte.github.io/tufte-css/)
// Styling inspired by Maggie Appleton's website
// (https://maggieappleton.com/)

const Footnote = ({ idName, children }) => {

  const footnoteStyles = css`

    transition: ${p => p.theme.colorModeTransition};

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }

    // side note content
    .sidenote,
    .marginnote {
        float: right;
        clear: right;
        margin-right: -45%;
        width: 40%;
        margin-top: 0px;
        margin-bottom: 10px;
        max-width: 250px !important;

        font-size: 14px;
        opacity: 1;
        line-height: 1.25;
        vertical-align: baseline;
        position: relative;
        border-left: 1px solid lightgrey;
        padding-left: 1em;
    }

    label {
      cursor: pointer;
    }

    .sidenote-number {
      counter-increment: sidenote-counter;
    }

    .sidenote-number:after,
    .sidenote:before {
      position: relative;
      vertical-align: baseline;
    }

    .sidenote-number:after {
      content: counter(sidenote-counter);
      font-size: 18px;
      top: -0.5rem;
      left: 0em;
      adding-right: 3px;
    }

    .sidenote:before {
      content: counter(sidenote-counter) " ";
      font-size: 15px;
      top: -0.5rem;
      padding-right: 5px;
    }

    blockquote .sidenote,
    blockquote .marginnote {
      margin-right: -82%;
      min-width: 59%;
      text-align: left;
    }

    label.sidenote-number {
      display: inline;
    }

    label.margin-toggle:not(.sidenote-number) {
      display: none;
    }

    input.margin-toggle {
      display: none;
    }

    ${mediaqueries.desktop`
      label.margin-toggle:not(.sidenote-number) {
        display: inline;

        .sidenote,
        .marginnote {
          display: none;
        },

        .margin-toggle:checked + .sidenote,
        .margin-toggle:checked + .marginnote {
          display: block;
          float: left;
          left: 1rem;
          clear: both;
          width: 95%;
          margin: 1rem 2.5%;
          vertical-align: baseline;
          position: relative;
        }
    `};

    ${mediaqueries.desktop_medium`
      .sidenote,
      .marginnote {
        position: relative;
        font-size: 12px;
        margin: 20px 0 0 30px;
      }
    `};

    ${mediaqueries.tablet`
      .sidenote,
      .marginnote {
        position: relative;
        font-size: 12px;
        margin: 20px 0 0 30px;
      }
    `};

    ${mediaqueries.phablet`
      .sidenote,
      .marginnote {
        position: relative;
        font-size: 12px;
        margin: 20px 0 0 30px;
      }
    `};
    `

  return (
    <span css={footnoteStyles}>
      <label for={idName} className="margin-toggle sidenote-number"></label>
      <input type="checkbox" id={idName} className="margin-toggle"/>
      <span className="sidenote">
        {children}
      </span>
    </span>
  )
}

export default Footnote
