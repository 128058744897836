import React from "react";
import styled from "@emotion/styled";
import mediaqueries from "@styles/media";
import { Icon } from '@types';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */

const Logo: Icon = ({ fill = "#000000" }) => {
  return (
    <LogoContainer>
      <svg
        width="30px"
        height="50px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="200 20 100 100" /* "min-x min-y width height" */
        preserveAspectRatio="xMidYMid">
        <g>
          <g transform="translate(200, 112)">
            <path
              d="M21.29-73.73L51.17-9.86L80.47-73.63L80.47-73.63Q81.64-73.44 83.74-73.39L83.74-73.39L83.74-73.39Q85.84-73.34 88.09-73.34L88.09-73.34L88.09-73.34Q90.43-73.34 92.48-73.44L92.48-73.44L92.48-73.44Q94.53-73.54 95.70-73.73L95.70-73.73L95.70-71.19L95.70-71.19Q90.43-71.19 88.43-69.04L88.43-69.04L88.43-69.04Q86.43-66.89 86.43-62.30L86.43-62.30L87.50-13.96L87.50-13.96Q87.60-10.74 88.09-8.64L88.09-8.64L88.09-8.64Q88.57-6.54 89.84-5.37L89.84-5.37L89.84-5.37Q91.02-4.20 93.26-3.71L93.26-3.71L93.26-3.71Q95.51-3.22 99.22-3.22L99.22-3.22L99.22-0.78L99.22-0.78Q96.88-0.78 92.87-0.78L92.87-0.78L92.87-0.78Q88.87-0.78 84.57-0.78L84.57-0.78L84.57-0.78Q80.27-0.78 76.37-0.78L76.37-0.78L76.37-0.78Q72.46-0.78 70.31-0.78L70.31-0.78L70.31-3.22L70.31-3.22Q73.54-3.22 75.63-3.66L75.63-3.66L75.63-3.66Q77.73-4.10 78.91-5.27L78.91-5.27L78.91-5.27Q80.08-6.45 80.57-8.54L80.57-8.54L80.57-8.54Q81.05-10.64 81.05-13.96L81.05-13.96L80.37-65.14L50.49-0.10L49.02-0.10L19.63-63.28L18.65-13.67L18.65-13.67Q18.65-8.01 20.21-5.71L20.21-5.71L20.21-5.71Q21.78-3.42 26.76-3.22L26.76-3.22L26.76-0.78L26.76-0.78Q24.41-0.78 21.29-0.83L21.29-0.83L21.29-0.83Q18.16-0.88 14.84-0.88L14.84-0.88L14.84-0.88Q11.52-0.88 8.40-0.88L8.40-0.88L8.40-0.88Q5.27-0.88 2.83-0.78L2.83-0.78L2.83-3.22L2.83-3.22Q6.05-3.32 8.35-3.71L8.35-3.71L8.35-3.71Q10.64-4.10 12.11-5.18L12.11-5.18L12.11-5.18Q13.57-6.35 14.31-8.40L14.31-8.40L14.31-8.40Q15.04-10.45 15.23-13.96L15.23-13.96L16.50-62.21L16.50-62.21Q16.50-67.09 14.16-69.14L14.16-69.14L14.16-69.14Q11.82-71.19 7.23-71.19L7.23-71.19L7.23-73.73L7.23-73.73Q8.79-73.54 10.79-73.49L10.79-73.49L10.79-73.49Q12.79-73.44 14.75-73.44L14.75-73.44L14.75-73.44Q16.70-73.44 18.46-73.49L18.46-73.49L18.46-73.49Q20.21-73.54 21.29-73.73L21.29-73.73Z"
              fill={fill}>
            </path>
          </g>
        </g>
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`

  svg {
    opacity: 1;
    transition: opacity 0.2s;
    path {
      fill: ${p => p.theme.colors.primary};
    }
  }

  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }

    .Logo__Mobile{
      display: block;
    }
  `}
`;
