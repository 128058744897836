import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const Blockquote = styled.blockquote`
  transition: ${p => p.theme.colorModeTransition};
  // background: ${p => p.theme.colors.card};
  border-left: 2px solid ${p => p.theme.colors.accent};
  border-radius: 0px;

  margin: 15px auto 50px;
  padding: .25em 25px;

  quotes: "\u201C" "\u201D";

  color: ${p => p.theme.colors.articleText};
  font-family: ${p => p.theme.fonts.serif};

  font-weight: 400;

  ${mediaqueries.tablet`
    margin: 10px auto 35px;
    max-width: 400px !important;
  `};

  & > span {
    font-family: ${p => p.theme.fonts.serif};
    font-size: ${p => p.theme.fontSizes.quote};
    text-align: right !important;
    line-height: 1.8;

    display: block;
    padding: 10px 0px 0px 0px;
  }

  & > p {

    font-family: ${p => p.theme.fonts.serif};
    font-style: italic;
    text-align: justify !important;
    max-width: 500px !important;
    padding-right: 0px;
    padding-bottom: 0;
    width: 100%;
    margin: 0 auto;
    font-size: ${p => p.theme.fontSizes.quote};
    font-weight: bold
    line-height: 1.8;

    &::before {
      color: ${p => p.theme.colors.hover};
      content: open-quote;
      font-size: 2em;
    
      line-height: .01em;
      vertical-align: -.3em;
    
      margin-left: -.15em;
      margin-right: .0em;
    }

    &::after {
      color: ${p => p.theme.colors.hover};
      content: close-quote;
      font-size: 2em;
    
      line-height: .01em;
      vertical-align: -.3em;
    
      margin-left: .01em;
     }

    ${mediaqueries.tablet`
      font-size: 20px;
      padding: 0 50px;
    `};

    ${mediaqueries.phablet`
      font-size: 20px;
      padding: 0 20px 0 20px;
    `};
  }
`;

export default Blockquote;
