import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

import "katex/dist/katex.min.css";

const Paragraph = styled.p`
  line-height: 1.8;
  font-size: ${p => p.theme.fontSizes.articleBody};
  color: ${p => p.theme.colors.articleText};
  font-family: ${p => p.theme.fonts.sansSerif};

  transition: ${p => p.theme.colorModeTransition};
  margin: 0 auto 35px;
  width: 100%;
  max-width: 680px;

  // text selection coloring
  // &::selection {
  //   color: ${p => p.theme.colors.primary};
  //   background-color: ${p => p.theme.colors.accent};
  //   opacity: 0.5;
  // };

  // counter-reset: sidenote-counter;

  b {
    font-weight: 600;
  }

  ${mediaqueries.desktop`
    max-width: 507px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
    margin: 0 auto 25px;
  `};

  ${mediaqueries.phablet`
    padding: 0 20px;
  `};
`;

export default Paragraph;
