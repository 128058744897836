import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';
import merge from 'lodash/merge';

// prism spec
const prism = {
  token: `#fff`,
  languageJavascript: `#e8696b`,
  javascript: `#e8696b`,
  background: 'rgba(0, 0, 0, 0.025)', // `#292c34`
  comment: `#5e6a76`,
  string: `#a8e2a8`,
  var: `#b3bac5`,
  number: `#e4854d`,
  constant: `#b3bac5`,
  plain: `#292c34`, // `#fff`
  numberLine: `#292c34`,
  doctype: `#e8696b`,
  tag: `#e8696b`,
  keyword: `#d49fd4`,
  boolean: `#ff5874`,
  function: `#5F8DC3`,
  parameter: `#F9965D`,
  className: `#ffcf74`,
  attrName: `#bf87ba`,
  attrValue: `#a8e2a8`,
  interpolation: `#fff`,
  punctuation: `#5FA8AA`,
  // ["maybe-class-name"]: `#fff`
  property: `#80cbc4`,
  propertyAccess: `#fff`,
  namespace: `#b2ccd6`,
  highlight: `rgba(255,255,255,0.07)`,
  highlightBorder: `#e1bde2`,
  dom: `#5F8DC3`,
  operator: `#5FA8AA`,
};

// tags spec
const tags = {
  pre: {
    theme: `shadesOfPurple`,
    variant: `prism`,
    fontFamily: `"Consolas", monospace`,
    tabSize: 4,
    hyphens: `none`,
    color: `white`,
    bg: `prism.background`,
    overflow: `auto`,
    borderRadius: 10,
    p: 3,
  },
  code: {
    theme: `shadesOfPurple`,
    variant: `prism`,
    bg: `prism.background`,
    fontFamily: `"Consolas", monospace`,
    fontSize: `inherit`,
  },
  inlineCode: {
    borderRadius: `0.3em`,
    color: `secondary`,
    bg: `rgba(233, 218, 172, 0.3)`,
    paddingTop: `0.15em`,
    paddingBottom: `0.05em`,
    paddingX: `0.2em`,
  },
};

// index constants
const breakpoints = [
  ['phone_small', 320],
  ['phone', 376],
  ['phablet', 540],
  ['tablet', 735],
  ['desktop', 1070],
  ['desktop_medium', 1280],
  ['desktop_large', 1440],
];

const fonts = {
  serif: "'Merriweather', Georgia, Serif", //"'Freight Sans', 'Cormorant Garamond' Serif",
  sansSerif: "'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif",
  quote: `Professor`,
  monospace: 'Menlo, monospace', // `"Operator Mono", Consolas, Menlo, Monaco, source-code-pro, monospace`,
};

const fontSizes = {
  articleBody: '18px',
  quote: '18px'
};

const colorModeTransition = 'background 1s var(--ease-in-out-quad), color 0.5s var(--ease-in-out-quad)';

// theme spec
export default merge({
  ...novelaTheme,

  // index
  initialColorMode: 'light',
  useCustomProperties: true,
  breakpoints,
  fonts,
  colorModeTransition,

  // tags
  tags,

  // font sizes
  fontSizes,

  // colors
  colors: {
    ...novelaTheme.colors,

    // ——— prism
    prism,

    // ——— colors
    background: '#fff', // '#fafafa'
    primary: '#000', // '#004933'
    secondary: '#73737D',
    grey: '#73737D',
    accent: '#c64d10', // '#6166DC'
    highlight: '#ABA8AF',


    // article properties
    hover: "rgba(0, 0, 0, 0.07)",
    gradient: 'linear-gradient(180deg, #FFFFFF 0%, #f4f2f1 100%)',
    articleText: '#08080B',
    track: 'rgba(8, 8, 11, 0.3)',
    progress: '#000',
    card: "rgba(0, 0, 0, 0.01)", // '#fff'

    error: '#EE565B',
    success: '#46B17B',
    errorBackground: 'rgba(238, 86, 91, 0.1)',
    horizontalRule: 'rgba(8, 8, 11, 0.15)',
    inputBackground: 'rgba(0, 0, 0, 0.05)',

    modes: {
      dark: {
        background: '#111216',
        primary: '#fff',
        secondary: '#fff',
        grey: '#73737D',
        accent: '#af9a60', // '#E9DAAC'
        highlight: '#ABA8AF',

        hover: 'rgba(255, 255, 255, 0.07)',
        gradient: 'linear-gradient(180deg, #111216 0%, rgba(66, 81, 98, 0.36) 100%)',
        articleText: '#fff',
        track: 'rgba(255, 255, 255, 0.3)',
        progress: '#fff',
        card: '#1D2128',
        error: '#EE565B',
        success: '#46B17B',
        errorBackground: 'rgba(238, 86, 91, 0.1)',
        horizontalRule: 'rgba(255, 255, 255, 0.15)',
        inputBackground: 'rgba(255, 255, 255, 0.07)',

        prism:{
          plain: '#fff',
          background: `#292c34`,
          numberLine: `#fff`
        },
      },
    },
  },
});
