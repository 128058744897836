import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const Textquote = styled.textquote`
  transition: ${p => p.theme.colorModeTransition};

  margin: 15px auto 50px;

  max-width: 500px !important;

  color: ${p => p.theme.colors.accent};
  font-family: ${p => p.theme.fonts.serif};
  font-size: 24px !important;
  // font-style: italic;
  font-weight: 200;
  text-align: center;

  ${mediaqueries.tablet`
    margin: 10px auto 35px;
    max-width: 400px !important;
  `};

  & > p {

    font-family: ${p => p.theme.fonts.serif};
    max-width: 500px !important;
    padding-right: 0px;
    padding-bottom: 0;
    width: 100%;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold
    line-height: 1.5;

    ${mediaqueries.tablet`
      font-size: 20px;
      padding: 0 50px;
    `};

    ${mediaqueries.phablet`
      font-size: 20px;
      padding: 0 20px 0 20px;
    `};
  }
`;

export default Textquote;
