import styled from "@emotion/styled";

const Anchor = styled.a`
  transition: ${p => p.theme.colorModeTransition};
  color: ${p => p.theme.colors.primary};
  text-decoration: underline;
  // text-decoration-color: ${p => p.theme.colors.accent};

  &:visited {
    opacity: 0.8;
    text-decoration: underline;
  }

  &:hover {
    color: ${p => p.theme.colors.accent};
    text-decoration: none;
    transition: 0.3s
  },
`;

export default Anchor;
